import usePrefix from '@/hooks/usePrefix';
import { IAirport, IGuide, IMonthly } from '@/types';
import { Button, Divider } from 'antd';
import Link from 'next/link';
import { DownOutlined, UpOutlined } from '@/components2/Icons';
import React, { ReactElement, useState } from 'react';

export interface IMonthlyList {
  airports: { result: IAirport[]; monthlys: IGuide[]; monthlies: IMonthly[] };
  grid?: number;
  gap?: number;
}

function MonthliesList({
  airports,
  grid = 3,
  gap = 2,
}: IMonthlyList): ReactElement {
  const prefix = usePrefix();
  return (
    <div
      className={`grid grid-cols-1 md:grid-cols-${grid} gap-y-2 gap-x-${gap} place-items-start`}
    >
      {airports?.monthlies?.map((monthly: IMonthly) => (
        <Link
          href={`${prefix}/monthly/${monthly.monthly_friendly_url}`}
          key={monthly.monthly_id}
          className="text-primary hover:underline hover:text-primary"
          title={monthly.monthly_name}
        >
          • {monthly.monthly_name}
        </Link>
      ))}
    </div>
  );
}

function Monthlies({ airports }): ReactElement {
  const [showAllMonthlies, setShowAllMonthlies] = useState(false);
  if (!airports?.monthlies || airports?.monthlies.length == 0) return <></>;
  return (
    <div className="container flex flex-col justify-center text-center w-full md:w-3/4 gap-4 my-4 mx-auto">
      <div
        className={`overflow-hidden ${
          showAllMonthlies ? 'h-auto pt-1.5' : 'h-14'
        } flex flex-col px-2 justify-center text-center border-y border-gray-300`}
      >
        <div className="w-full flex flex-row justify-between items-center">
          <h2 className="text-sm md:text-base font-bold text-left">
            Our Monthly Parking
          </h2>

          {/* <div className="flex flex-row text-center justify-center mt-2 md:justify-end md:mt-0 items-center"> */}
          <Button
            size="large"
            className="border-none bg-transparent md:text-xl text-primary"
            onClick={() => setShowAllMonthlies(!showAllMonthlies)}
          >
            {showAllMonthlies ? <UpOutlined /> : <DownOutlined />}
          </Button>
          {/* </div> */}
        </div>
        <div
          className={`w-full pt-4 ${showAllMonthlies ? 'visible' : 'hidden'}`}
        >
          <MonthliesList airports={airports} />
        </div>
      </div>
    </div>
  );
}

export default Monthlies;
